import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Header from "../header"
import Footer from "../footer"
import Author from "../author"
import BlogThumbnail from "../blog-thumbnail"
import ShareButtons from "../share-buttons"
import BuyMeACoffee from "../buy-me-a-coffee"

import {
  title as titleStyle,
  description as descriptionStyle,
  body as bodyStyle,
  meta as metaStyle,
  links as linksStyle,
  nextLinkBox as nextLinkBoxStyle,
  prevLinkBox as prevLinkBoxStyle,
  linkBoxHeader as linkBoxHeaderStyle,
  link as linkStyle,
  linkIcon as linkIconStyle,
  linkText as linkTextStyle,
} from "./index.css"

const BlogLayout = ({
  siteTitle,
  title,
  url,
  prev,
  next,
  thumbnail,
  description,
  publishedDate,
  author,
  updatedDate,
  children,
}) => {
  const nextLink = next &&
    <div className={nextLinkBoxStyle}>
      <span
        className={linkBoxHeaderStyle}
      >
        次の記事
      </span>
      <Link
        to={next.path}
        className={linkStyle}
      >
        <FontAwesomeIcon
          className={linkIconStyle}
          icon="arrow-circle-left"
        />
        <span className={linkTextStyle}>
          {next.title}
        </span>
      </Link>
    </div>

  const prevLink = prev &&
    <div className={prevLinkBoxStyle}>
      <span
        className={linkBoxHeaderStyle}
      >
        前の記事
      </span>
      <Link
        to={prev.path}
        className={linkStyle}
      >
        <span className={linkTextStyle}>
          {prev.title}
        </span>
        <FontAwesomeIcon
          className={linkIconStyle}
          icon="arrow-circle-right"
        />
      </Link>
    </div>

  const links = (next || prev) &&
    <div className={linksStyle}>
      {nextLink}
      {prevLink}
    </div>

  return (
    <>
      <Header siteTitle={siteTitle} />
      <main className={bodyStyle}>
        <div className="container">
          <h1 className={titleStyle}>{title}</h1>
          <p className={descriptionStyle}>{description}</p>
          <div className={metaStyle}>
            <Author
              author={author}
              publishedDate={publishedDate}
              updatedDate={updatedDate}
            />
            <ShareButtons
              title={title}
              url={url}
            />
          </div>
        </div>
        <BlogThumbnail
          thumbnail={thumbnail}
        />
        <div className="container">
          {children}
        </div>
        <div className="container">
          <BuyMeACoffee />
        </div>
        <div className="container">
          {links}
        </div>
      </main>
      <Footer copyright={author} />
    </>
  )
}

export default BlogLayout
