import "src/components/blog-layout/index.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/blog-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VT24rbMBB9z1cMKYVeouBNm03qQGnz0BbKLoX9gKJI41hdWTKSnEtL/n1HWofEyaZd6JPR6Jzx0Zwzw59XommqsMrgTw+g4m6pDFvYEGyVw5XDatbb9YZ71CihNIaAjvmaC2WWOQyz8SnwHbxJ0MKawApeKb3NgfG61sj81gesBjDXytzfcHGXzl8IOYD+N9QrDEpwuMUG+wP47BTX8UI5TtosfOf3DXy1oVQCfjh72z++vOPGU+EGldvaAXg6Mo9OFR1175M2qXytOekqNG5mUSx9mVQORVDW5ODsOpa5VkvDFGn0OQg09PZY/tX4oIotEyScijnEeSBbYFgjmtnz5jR+nhJhdVOlnmslQ0nOZNnLeCxRLctwOLcGBlvv3fu3qWP4SG69bR0773AEvf4vuZ1GPLUipHU5vMiy6Xw6j4yFdRKpNK434K1W8nDZ5eelXaFLXWyccCBB2XAaewRHvqtHLVxrKo98hz5JtI6x6Q0+cBdSB9wElu5zsrEIHfb0AhuNPOW6aE+H/OHCu9OmrFs7F1bLDos/PfgL0WynPu1mZDIct4mouZQpkfuItHLWJbU6+e9ZMuI4KMtnUV4cFt6r35jD6BwjEuYkFJ8qlIqDF472BriR8KriG9aiJteTevM68U7294l5XIoic0hZ8fiI+Yvvu+5vjhfjwiJFzu4BdOUfREcFAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var body = '_1cuumtv3';
export var description = '_1cuumtv2 _1cuumtv0';
export var link = '_1cuumtva';
export var linkBoxHeader = '_1cuumtv9';
export var linkIcon = '_1cuumtvb';
export var linkText = '_1cuumtvc';
export var links = '_1cuumtv5';
export var meta = '_1cuumtv4';
export var nextLinkBox = '_1cuumtv7 _1cuumtv6';
export var prevLinkBox = '_1cuumtv8 _1cuumtv6';
export var title = '_1cuumtv1 _1cuumtv0';