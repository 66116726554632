import React from "react"
import { container, brandLogo } from './index.css'

const BuyMeACoffee = () => {
  return (
    <div className={container}>
      <p>
        この記事がお役に立てたら一杯のコーヒーを恵んで頂けると励みになります 😊
      </p>
      <a
        href="https://www.buymeacoffee.com/soudai"
        target="_blank"
      >
        <img
          className={brandLogo}
          src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
          alt="Buy Me A Coffee"
        />
      </a>
    </div>
  );
}

export default BuyMeACoffee
