import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { blogThumbnail as blogThumbnailStyle } from "./index.css"

const BlogThumbnail = ({ thumbnail }) => (
  <GatsbyImage
    image={thumbnail.gatsbyImageData}
    className={blogThumbnailStyle}
    alt=""
    loading="eager"
    fadin="false"
  />
)

export default BlogThumbnail
