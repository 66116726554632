import React from "react"
import PropTypes from "prop-types"

import {
  FacebookShareButton,
  FacebookIcon,
  HatenaShareButton,
  HatenaIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import { shareButtons, button } from "./index.css"

const ICON_SIZE = 32

const ShareButtons = ({ url, title }) => (
  <div className={shareButtons}>
    <HatenaShareButton
      url={url}
      className={button}
    >
      <HatenaIcon size={ICON_SIZE} round />
    </HatenaShareButton>
    <TwitterShareButton
      title={title}
      url={url}
      className={button}
    >
      <TwitterIcon size={ICON_SIZE} round />
    </TwitterShareButton>
    <FacebookShareButton
      url={url}
      className={button}
    >
      <FacebookIcon size={ICON_SIZE} round />
    </FacebookShareButton>
  </div>
)

ShareButtons.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ShareButtons
