import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { container } from './index.css'

const AuthorIcon = ({ author }) => (
  <StaticImage
    src="../../images/author.JPG"
    alt={author}
    className={container}
    width={45}
  />
)

export default AuthorIcon
