import React from "react"

import AuthorIcon from "../author-icon"
import {
  container,
  infoBox,
  authorName,
  publishedDate as publishedDateStyle,
  updatedDate as updatedDateStyle
} from "./index.css"

const Author = ({ author, publishedDate, updatedDate }) => (
  <div className={container}>
    <AuthorIcon author={author} />
    <div className={infoBox}>
      <p className={authorName}>{author}</p>
      <time className={publishedDateStyle}>公開日 {publishedDate}</time>
      { updatedDate &&
        <time className={updatedDateStyle}>更新日 {updatedDate}</time>
      }
    </div>
  </div>
)

export default Author
