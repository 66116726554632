import React from "react"
import { graphql } from "gatsby"
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"
import ja from "date-fns/locale/ja"
import BlogLayout from "../../components/blog-layout"
import Meta from "../../components/meta"
import { blogPost } from "./index.css"

const BlogPost = ({ data }) => {
  const { title, slug, description, thumbnail, body, category, tags, publishedAt, updatedAt } = data.contentfulBlogPost
  const tagNames = tags.map(tag => tag.name)
  const keyword = [category.contentfulid, category.name, ...tagNames].join(",")
  const siteTitle = data.site.siteMetadata.title
  const author = data.site.siteMetadata.author
  const url = `${data.site.siteMetadata.siteUrl}/${slug}`

  const older = data.allContentfulBlogPost.edges[0]
  const newer = data.allContentfulBlogPost.edges[1]

  let prevNode = null
  let nextNode = null
  if (newer) {
    prevNode = older
    nextNode = newer
  }
  if (older && !newer) {
    if (older.node.publishedAt < publishedAt) {
      prevNode = older
    } else {
      nextNode = older
    }
  }

  const prev = prevNode ?
    {
      title: prevNode.node.title,
      path: `/${prevNode.node.slug}`,
    } : null
  const next = nextNode ?
    {
      title: nextNode.node.title,
      path: `/${nextNode.node.slug}`,
    } : null

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": title,
    "image": [
      `https:${thumbnail.file.url}`,
    ],
    "datePublished": publishedAt,
    "dateModified": updatedAt,
  }

  const publishedDate = format(parseISO(publishedAt), "yyyy年MM月dd日", { locale: ja })
  const updatedDate = format(parseISO(updatedAt), "yyyy年MM月dd日", { locale: ja })

  return (
    <BlogLayout
      siteTitle={siteTitle}
      title={title}
      url={url}
      prev={prev}
      next={next}
      thumbnail={thumbnail}
      description={description}
      author={author}
      publishedDate={publishedDate}
      updatedDate={updatedDate}
    >
      <Meta
        title={title}
        keyword={keyword}
        description={description}
        meta={[
          { name: "og:url", content: url },
          { name: "og:image", content: `https:${thumbnail.file.url}` }
        ]}
        link={[
          { rel: "canonical", href: url }
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Meta>
      <div
        className={blogPost}
        dangerouslySetInnerHTML={{
          __html: body.childMarkdownRemark.html
        }}
      />
    </BlogLayout>
  );
};
export default BlogPost;
export const pageQuery = graphql`
  query($slug: String!, $nextSlug: String, $prevSlug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description
      thumbnail {
        gatsbyImageData(
          width: 2400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      category {
        contentfulid
        name
      }
      tags {
        name
      }
      publishedAt
      updatedAt
    }
    allContentfulBlogPost(
      filter: {
        slug: { in: [$nextSlug, $prevSlug] },
        node_locale: { eq: "ja-JP" },
        published: { eq: true }
      },
      sort: { fields: publishedAt }
    ) {
      edges {
        node {
          title
          slug
          publishedAt
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "author.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          width: 50
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
  }
`;
